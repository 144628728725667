<template>
  <div class="container">
    <form @submit.prevent="login">
      <h2>Login</h2>
      <input type="email" v-model="email" placeholder="Email address..." />
      <input type="password" v-model="password" placeholder="password..." />
      <button>Login</button>
    </form>
  </div>
</template>

<script>
import { API } from 'aws-amplify'

export default {
  name: "360Learning SSO",
  async beforeCreate () {
    API
      .get("SSOApi", "/api/sso/360learning")
      .then(response => {
        window.location.href = response['redirect'];
      })
      .catch(error => {
        console.log(error.response);
    });
  },
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {},
};
</script>
